.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-picker-content {
  background-color: #3e4249;
  color: "white";
}
.ant-picker-dropdown .ant-picker-time-panel {
  background-color: #3e4249;
}

.ant-picker .ant-picker-input {
  color: #ffffff !important;
}
.ant-picker .ant-picker-input > input:hover {
  opacity: 0;

  background-color: #3e4249;
}
.ant-picker-dropdown .ant-picker-panel .ant-picker-footer {
  background-color: #3e4249;
}
.ant-btn-primary {
  background-color: #3e4249;
}
.ant-picker .ant-picker-input > input {
  color: #ffffff !important;
}
.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #3e4249;
}
.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  color: rgba(255, 255, 255, 1) !important;
}

.fc-day {
  background: rgba(0, 0, 0, 0) !important;
  /* border: red !important; */
  width: 100%;
}

/* .fc .fc-content .fc-event-container .fc-event {
  background: #ef6262 !important;
  border: 0px solid rgba(0, 0, 0, 0) !important;
  color: #fff !important;
  border-radius: 5px;
  width: 100%;
} */

.fc th,
.fc td {
  border: none;
  width: 100%;
}

.fc .fc-scrollgrid-liquid {
  height: 85vh !important;
  border-radius: 12px !important;
  width: 100% !important;
  flex: 1 !important;
  width: 100%;
}

.fc .fc-col-header-cell-cushion {
  justify-self: flex-start;
  padding: 8px;
  color: white;
  width: 100%;
}

.fc-scrollgrid-section-liquid {
  border-radius: 0px !important;
  border: 0px solid rgba(0, 0, 0, 0) !important;
  outline: 0px solid rgba(0, 0, 0, 0) !important;
  width: 100%;
}

.fc .fc-scrollgrid table {
  outline: 0px solid rgba(0, 0, 0, 0);
}

.fc .fc-scrollgrid-section,
.fc .fc-scrollgrid-section table,
.fc .fc-scrollgrid-section > td {
  outline: 0px solid rgba(0, 0, 0, 0);
}

.fc-col-header table {
  outline: 0px solid rgba(0, 0, 0, 0);
}
.fc-col-header-cell .fc-day-saturday table {
  outline: 0px solid rgba(0, 0, 0, 0);
}

.fc-col-header-cell .fc-day .fc-day-sat .fc-day-future {
  outline: 0px solid #30343c;
}

.fc .fc-scrollgrid-sync-inner {
  outline: 0px solid #30343c;
  width: 100%;

  font-size: larger;
  color: white;
}

:root {
  --fc-border-color: var(--dynamic-color) !important;
  width: 100%;
  --dynamic-color: #defaultColor;
  --fc-border-radius: 5px !important;
}

.fc .fc-timegrid-slot {
  height: 2em !important;
  width: 100%;
  outline: 0px solid rgba(0, 0, 0, 0);
}

.fc-timegrid-slot .fc-timegrid-lane {
  border: 15px solid red !important;
  z-index: 100;
}

.fc .fc-timegrid-slot-minor {
  border: 0px solid black !important;
  width: 100%;
}

.fc .fc-daygrid-day-frame {
  min-height: 100%;
  position: relative;
  width: 100%;
}

.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  padding: 0 4px;
  font-size: 12px;
}

.fc-timegrid-event-harness .fc-timegrid-event-harness-inset {
  border: 0px solid black;
  background-color: red;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  z-index: 100;
  width: 100%;
}

td {
  cursor: pointer;
}

.fc td,
.fc th {
  /* border-width: 1px !important; */
  border-radius: 16px 0px 16px 16px !important;
  width: 100%;
  margin: 0px;
  max-width: 1500px;
}

.fc-event-main {
  box-shadow: 0 0 0 0px var(--fc-page-bg-color);
  border: 0px solid rgba(0, 0, 0, 0);
  outline: 0px solid rgba(0, 0, 0, 0);
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: 0 0 0 0px var(--fc-page-bg-color) !important;
  border: 0px solid rgba(0, 0, 0, 0) !important;
  outline: 0px solid rgba(0, 0, 0, 0) !important;
}

::-webkit-scrollbar {
  display: none;
  /* height: 5px;
  width: 5px;
  background: #232529;
  -webkit-border-radius: 1ex; */
}

a {
  background-color: rgba(0, 0, 0, 0) !important;
}

::-webkit-scrollbar-thumb {
  background: #30343c;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: #232529;
}

.vercel [cmdk-root] {
  max-width: 640px;
  width: 100%;
  padding: 8px;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  font-family: var(--font-sans);
  border: 1px solid var(--gray6);
  box-shadow: var(--cmdk-shadow);
  transition: transform 100ms ease;
}

.dark .vercel [cmdk-root] {
  background: rgba(22, 22, 22, 0.7);
}
.vercel [cmdk-input] {
  font-family: var(--font-sans);
  border: none;
  width: 100%;
  font-size: 17px;
  padding: 8px 8px 16px 8px;
  outline: none;
  background: var(--bg);
  color: var(--gray12);
  border-bottom: 1px solid var(--gray6);
  margin-bottom: 16px;
  border-radius: 0;
}
.vercel [cmdk-input]::placeholder {
  color: var(--gray9);
}
.vercel [cmdk-vercel-badge] {
  height: 20px;
  background: var(--grayA3);
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  font-size: 12px;
  color: var(--grayA11);
  border-radius: 4px;
  margin: 4px 0 4px 4px;
  user-select: none;
  text-transform: capitalize;
  font-weight: 500;
}
.vercel [cmdk-item] {
  content-visibility: auto;
  cursor: pointer;
  height: 48px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  color: var(--gray11);
  user-select: none;
  will-change: background, color;
  transition: all 150ms ease;
  transition-property: none;
}
.vercel [cmdk-item][aria-selected="true"] {
  background: var(--grayA3);
  color: var(--gray12);
}
.vercel [cmdk-item][aria-disabled="true"] {
  color: var(--gray8);
  cursor: not-allowed;
}
.vercel [cmdk-item]:active {
  transition-property: background;
  background: var(--gray4);
}
.vercel [cmdk-item] + [cmdk-item] {
  margin-top: 4px;
}
.vercel [cmdk-item] svg {
  width: 18px;
  height: 18px;
}
.vercel [cmdk-list] {
  height: 400px;
  max-height: 400px;
  overflow: auto;
  overscroll-behavior: contain;
  transition: 100ms ease;
  transition-property: height;
}
.vercel [cmdk-vercel-shortcuts] {
  display: flex;
  margin-left: auto;
  gap: 8px;
}
.vercel [cmdk-vercel-shortcuts] kbd {
  font-family: var(--font-sans);
  font-size: 12px;
  min-width: 20px;
  padding: 4px;
  height: 20px;
  border-radius: 4px;
  color: var(--gray11);
  background: var(--gray4);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.vercel [cmdk-separator] {
  height: 1px;
  width: 100%;
  background: var(--gray5);
  margin: 4px 0;
}
.vercel *:not([hidden]) + [cmdk-group] {
  margin-top: 8px;
}
.vercel [cmdk-group-heading] {
  user-select: none;
  font-size: 12px;
  color: var(--gray11);
  padding: 0 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.vercel [cmdk-empty] {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  white-space: pre-wrap;
  color: var(--gray11);
}
