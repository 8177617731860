@font-face {
  font-family: "Space Grotesk";
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts/SpaceGrotesk-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  font-weight: bold;
  font-style: normal;
  src: url("../assets/fonts/SpaceGrotesk-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  font-weight: lighter;
  font-style: normal;
  src: url("../assets/fonts/SpaceGrotesk-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  font-weight: 500;
  font-style: normal;
  src: url("../assets/fonts/SpaceGrotesk-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  font-weight: 300;
  font-style: normal;
  src: url("../assets/fonts/SpaceGrotesk-Medium.ttf");
  font-display: swap;
}
