.fc {
  /* the calendar root */
  max-width: 1500px;
  margin: 0 auto;
}

.calHeader {
  font-size: 12px;
  font-weight: 200;
  font-family: inherit;
  background-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  outline: 0px solid rgba(0, 0, 0, 0);
  border-radius: 16px;
}

.fc-col-header-cell .fc-day .fc-day-sat .fc-day-future {
  outline: 0px solid #30343c;
}
